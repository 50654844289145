import Api from "./carabiMultiApi"
import Session from "./session.mjs"

export default class CSWebCore {
  constructor() {
    this.config = {}
    /** @type {Api} */
    this.api = null
    /** @type {Session} */
    this.session = null
    
    //this.eventer = 'test'
    //this.views = 'test'
    //this.plugins = 'test'
  }

  async init(config) {
    console.log('CsWeb core init...')
    this.config = config
    this.api = new Api(this.config)
    this.session = new Session(this.config)

    await this.session.load()
    if (this.session.value) {
      let sess = this.session.value
      this.api.setToken(sess.token,sess.project)
    }    
  }
}