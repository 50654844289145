
import IQueries from '../queries'
import GatewayBase from '../gatewayBase'
import { UserError } from '@/csweb-core/utils/errors'

export default class ApiDocumentCards {
    constructor(isGateway=false, queries=null, gateway=null) {
        this.isGateway = isGateway
        /** @type {IQueries} */
        this.carabi = queries
        /** @type {GatewayBase} */
        this.gateway = gateway
    }

    //Вынести потом в Tasks
    async listTasks(documentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-cards/list-tasks", {
                documentId
            })
        } else {
            return await this.carabi.select("CARD_GET_TASK_LIST_CS", { DOC_ID: documentId }, 1000)
        }
    }

    async getCardInfo(documentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-cards/get-card-info", {
                documentId
            })
        } else {
            return await this.carabi.selectRow('CARD_DOCUMENT_INFO2', {DOCUMENT_ID: documentId})
        }
    }

    //Транзакция карточки
    async transactionClearRefs(cardId,documentId,propName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-cards/transaction-clear-refs", {
                cardId, documentId, propName
            })
        } else {
            return await this.carabi.query("TRANSACTION_CLEAR_REFS", {
                CARD_ID: cardId, //number
                DOCUMENT_ID: documentId, //number
                REF_NAME: propName //varchar
            })
        }
    }

    async transactionInsertRefs(cardId, documentId, propName, newValues) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-cards/transaction-insert-refs", {
                cardId, documentId, propId, newValues
            })
        } else {
            return await this.carabi.query( "CRM_INSERT_REFS_BY_PROP_NAME", {
                CARD_ID : cardId, //number
                DOCUMENT_ID : documentId, //number
                DOCPROP_NAME : propName, //varchar
                REF_LIST : newValues.join(',') //clob
            })
        }
    }

    async transactionSetValues(cardId, documentId, propName, newValues) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-cards/transaction-set-values", {
                cardId, documentId, propName, newValues
            })
        } else {
            return await this.carabi.query( "TRANSACTION_SET_VALUES", {
                CARD_ID : cardId, //number
                DOCUMENT_ID : documentId, //number
                PROP_NAME : propName, //varchar
                VALUES : newValues.join(",") //clob
            })
        }
    }
    async transactionSetValue(cardId, documentId, propName, newValue) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-cards/transaction-set-value", {
                cardId, documentId, propName, newValue
            })
        } else {
            return await this.carabi.query( "TRANSACTION_SET_VALUE", {
                CARD_ID : cardId, //number
                DOCUMENT_ID : documentId, //number
                PROP_NAME : propName, //varchar
                VALUE : newValue //clob
            })
        }
    }

    async transactionCommit(cardId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-cards/transaction-commit", {
                cardId
            })
        } else {
            let result = await this.carabi.query("TRANSACTION_COMMIT", {
                CARD_ID : cardId //number
            })
            if (result.ERRMESS) {
                throw new UserError(result.ERRMESS)
            }
            return result
        }
    }

    async transactionRollback(cardId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-cards/transaction-rollback", {
                cardId
            })
        } else {
            return await this.carabi.query("TRANSACTION_ROLLBACK", { CARD_ID : cardId })
        }
    }

    //Представление карточки
    async listViewGroups(documentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-cards/list-view-groups", {
                documentId
            })
        } else {
            return await this.carabi.select('CRM_CARD_VIEW_GROUPS', {document_id: documentId}, 1000)
        }
    }

    async listViewGroupProperties(documentId, pluginId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-cards/list-view-group-properties", {
                documentId, pluginId
            })
        } else {
            return await this.carabi.select("CARD_VIEW_PROPERTIES",{
                "VIEW_GROUP_ID" : pluginId, //number
                "DOCUMENT_ID" : documentId //number
            },1000)
        }
    }
}