import AppserverClass from '@/csweb-core/carabiMultiApi/appserver'
import GatewayBase from './gatewayBase'

import IFileStorage from '@/csweb-core/carabiMultiApi/file-storage'
import FileStorageAppserv from '@/csweb-core/carabiMultiApi/file-storage/appserver'
import FileStorageMclStorage from '@/csweb-core/carabiMultiApi/file-storage/mclstorage'
import FileStorageMclFiles from '@/csweb-core/carabiMultiApi/file-storage/mclfiles'

import IQueries from '@/csweb-core/carabiMultiApi/queries'
import QueriesAppserv from '@/csweb-core/carabiMultiApi/queries/appserver'
import QueriesOra from '@/csweb-core/carabiMultiApi/queries/mclora'
import QueriesOraAlias from '@/csweb-core/carabiMultiApi/queries/ora-alias-cli'
import QueriesPgAliasCli from '@/csweb-core/carabiMultiApi/queries/pg-alias-cli'

import IAuth from '@/csweb-core/carabiMultiApi/auth'
import AuthAppserv from '@/csweb-core/carabiMultiApi/auth/appserver'
import AuthNats from '@/csweb-core/carabiMultiApi/auth/nats'

import ApiDocuments from './documents'
import ApiDocumentCards from './cards'
import ApiHistory from './history'
import ApiReports from './reports'
import ApiDocumentFilters from './document-filters'
import ApiDocumentImports from './document-imports'
import ApiDoclist from './doclist'

export default class Api {
    constructor(config) {
        this.config = config
        this.defaultProject = config.project
        this.authorised = false
        this.project = null
        this.token = null

        /** @type {GatewayBase} */
        this.gateway = null
        if (config.gateway) {
            console.log('init gateway',config.gateway)
            this.gateway = new GatewayBase()
            this.gateway.setAddress(config.gateway)
        }
        if (config.appserver) {
            console.log('init appserver',config.appserver)
            this.appserver = new AppserverClass(config.appserver, this.defaultProject)
        }

        /** @type {IQueries} */
        this.queries = null
        if (config?.api?.queries) {
            if (config.api.queries === "appserv") {
                console.log('init queries QueriesAppserv',this.appserver)
                this.queries = new QueriesAppserv(this.appserver)
            }
            else if (config.api.queries === "mclora") {
                console.log('init queries QueriesOra',this.gateway)
                this.queries = new QueriesOra(this.gateway)
            }
            else if (config.api.queries === "ora-alias") {
                console.log('init queries QueriesOraAliasCli',this.gateway)
                this.queries = new QueriesOraAlias(this.gateway)
            }            
            else if (config.api.queries === "mclpg") {
                console.log('init queries QueriesPgAliasCli',this.gateway)
                this.queries = new QueriesPgAliasCli(this.gateway)
            } else {
                throw new Error("wrong config.api.queries")
            }
        } else {
            throw new Error("config.api.queries not set")
        }

        /** @type {IFileStorage} */
        this.fileStorage = null
        console.log('init fileStorage',config?.api?.fileStorage)
        if (config?.api?.fileStorage) {
            if (config.api.fileStorage === "appserv") {
                console.log('init fileStorage appserv',this.appserver)
                this.fileStorage = new FileStorageAppserv(this.appserver)
            }
            else if (config.api.fileStorage === "mclfiles") {
                console.log('init fileStorage nats',this.gateway)
                this.fileStorage = new FileStorageMclFiles(this.gateway)
            }
            else if (config.api.fileStorage === "mclstorage") {
                console.log('init fileStorage nats',this.gateway)
                this.fileStorage = new FileStorageMclStorage(this.gateway)
            } 
            else {
                throw new Error("wrong config.api.fileStorage")
            }
        } else {
            throw new Error("config.api.fileStorage not set")
        }

        /** @type {IAuth} */
        this.auth = null
        console.log('init auth',config?.api?.auth)
        if (config?.api?.auth) {
            if (config.api.auth === "appserv") {
                console.log('init auth appserv',this.appserver)
                this.auth = new AuthAppserv(this.appserver)
            }
            else if (config.api.auth === "gateway") {
                console.log('init auth nats',this.gateway)
                this.auth = new AuthNats(this.gateway)
            } else {
                throw new Error("wrong config.api.auth")
            }
        } else {
            throw new Error("config.api.auth not set")
        }

        /** @type {ApiDocuments} */
        this.documents = null
        /** @type {ApiDocumentCards} */
        this.cards = null
        /** @type {ApiHistory} */
        this.history = null
        /** @type {ApiReports} */
        this.reports = null
        /** @type {ApiDocumentFilters} */
        this.documentFilters = null
        /** @type {ApiDocumentImports} */
        this.documentImports = null
        /** @type {ApiDoclist} */
        this.doclist = null
        if (config?.api?.documents) {
            if (config.api.documents === "queries") {
                this.documents = new ApiDocuments(false, this.queries, null)
                this.cards = new ApiDocumentCards(false, this.queries, null)
                this.history = new ApiHistory(false, this.queries, null)
                this.reports = new ApiReports(false, this.queries, null)
                this.documentFilters = new ApiDocumentFilters(false, this.queries, null)
                this.documentImports = new ApiDocumentImports(false, this.queries, null)
                this.doclist = new ApiDoclist(false, this.queries, null)
            }
            else if (config.api.documents === "gateway") {
                //Не используется (по кр. мере пока)
                this.documents = new ApiDocuments(true, null, this.gateway)
                this.cards = new ApiDocumentCards(true, null, this.gateway)
                this.history = new ApiHistory(true, null, this.gateway)
                this.reports = new ApiReports(true, null, this.gateway)
                this.documentFilters = new ApiDocumentFilters(true, null, this.gateway)
                this.documentImports = new ApiDocumentImports(true, null, this.gateway)
                this.doclist = new ApiDoclist(true, null, this.gateway)
            } else {
                throw new Error("wrong config.api.documents")
            }
        } else {
            throw new Error("config.api.documents not set")
        }
    }

    setToken(newToken, project=null) {
        //console.log('api setToken',newToken, project)
        this.token = newToken
        this.project = project
        if (this.gateway) {
            this.gateway.setToken(newToken, project || this.defaultProject)
        }
        if (this.appserver) {
            this.appserver.project = project
            this.appserver.authorized = !!newToken
            this.appserver.token      = newToken
        }
    }

    async login(login, password, project=null) {
        //Проект по-умолчанию
        if (!project) project = this.defaultProject
        let newToken = await this.auth.login(project, login, password)
        console.log("newToken",newToken)
        this.setToken(newToken,project)
    }

    async logout() {
        this.auth.logout().then(()=>{})
        this.setToken(null)
    }
}