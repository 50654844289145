//import IQueries from '../queries'
import IFileStorage from './index'
import GatewayBase from '../gatewayBase'
import UrlResolver from './url-resolver'
import { UserError } from '@/csweb-core/utils/errors'

export default class FileStorageMclStorage extends IFileStorage {
    constructor(gateway) {
        super()
        this.resolver = new UrlResolver()
        ///** @type {IQueries} */gateway
        //this.queries = queries
        /** @type {GatewayBase} */
        this.gateway = gateway
    }

    async getAttachList(documentId) {
        return await this.gateway.serverHttp("POST",'storage-master/list',{
            "parentId": documentId,
            "parentType": "document"
        })
    }


    async getFilesList(folderId=0) {
        return await this.gateway.serverHttp("POST",'storage-master/list',{
            "parentId": folderId,
            "parentType": "folder"
        })
    }

    async getFoldersList(folderId=0) {
        return await this.gateway.serverHttp("POST",'storage-master/folder-list',{
            "parentId": folderId,
            "parentType": "folder"
        })
    }

    async getAttachUrl(fileId) {
        let result = await this.gateway.serverHttp("POST",'storage-master/download',{
            "fileId": fileId
        })
        let resolved = await this.resolver.resolveArray(result.baseUrls)
        if (!resolved) throw new Error(`Хранилище ${result.storageName} не доступно`)
        return `${resolved.url}/download/${result.storageToken}/${result.filename}`
    }

    async getFileUrl(curFile) { 
        return await this.getAttachUrl(curFile.DOCUMENT_ID)
    }    

    async deleteAny(fileId, parentId, parentType) {
        await this.gateway.serverHttp("POST",'storage-master/delete',{
            "parentId": parentId,
            "parentType": parentType,
            "fileId": fileId
        })
    }

    async deleteAttach(fileId, parentId) {
        await this.deleteAny(fileId, parentId, 'document')
    }

    async deleteFile(fileId, parentId) {
        await this.deleteAny(fileId, parentId, 'folder')
    }    

    async upload(parentId, parentType, file, onUploadProgress = null) {
        let uploadInfo = await this.gateway.serverHttp("POST",'storage-master/upload',{
            "parentId": parentId,
            "parentType": parentType
        })
        let resolved = await this.resolver.resolveArray(uploadInfo.baseUrls)
        if (!resolved) throw new Error(`Хранилище ${uploadInfo.storageName} не доступно`)
        let url = `${resolved.url}/upload/${uploadInfo.storageToken}`
        let formData = new FormData()
        formData.append("file", file)
        let result = await this.uploadAsync(url,formData,onUploadProgress)
        console.log('Upload result',result)

        if (result.fileId === undefined) {
          throw new UserError("Ошибка в результате загрузки");
        }
        return result.fileId
    }

    async uploadAttach(parentId, file, onUploadProgress = null) {
        return await this.upload(parentId, 'document', file, onUploadProgress)
    }

    async uploadFile(folderId, file, onUploadProgress = null) {
        return await this.upload(folderId, 'folder', file, onUploadProgress)
    }

}