import Api from '../../csweb-core/carabiMultiApi/index.js'

export default class Adapter {
  /**
   * @param {Api}} api 
   */
  constructor (api) {
    /** @type Api  */
    this.api = api
  }

  get token() {
    return this.api.token
  }

  // получить токен (автосоздание гостевого)
  async getToken () {
    return this.api.token
  }

  // Запрос (обработка вывода)
  async query (queryName, params, count, offset, options={}) {
    return await this.api.queries.query(queryName, params, count, offset, {...options, convertDate: true})
  }

  // Запрос с единственным результатом
  async select (queryName, params, count, offset, options={}) {
    return await this.api.queries.select(queryName, params, count, offset, {...options, convertDate: true})
  }

  // Запрос с таблицой (только первый ряд)
  async selectRow (queryName, params, options={}) {
    return await this.api.queries.selectRow(queryName, params, {...options, convertDate: true})
  }

  dateToIso(date) {
    if (date === null) return null;
    let re = new RegExp("^([0-9]{2})\.([0-9]{2})\.([0-9]{4})([\^\s]([0-9]{2}:[0-9]{2}:[0-9]{2}))?$");
    let d = date.match(re);
    if (!d) return null;
    let result = d[3]+'-'+d[2]+'-'+d[1];
    if ((d.length > 4) && (d[5])) {
      result += 'T'+d[5];
    } else {
      result += 'T00:00:00';
    }
    return result
  }

}
