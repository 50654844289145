import Event from "./utils/event"

class Session {
  constructor() {
    this.value = null
    this.onChanged = new Event()
  }

  set(newSession) {
    localStorage.setItem('session', JSON.stringify(newSession))
    this.value = newSession
    this.onChanged.emit(this.value)
  }

  clear() {
    localStorage.removeItem('session');
    this.value = null
    this.onChanged.emit(this.value)
  }
  
  async load() {
    try {
      let newSession = localStorage.getItem('session')
      if (!newSession) {
        this.clear()
        return;
      }
      newSession = JSON.parse(newSession)
      this.value = newSession
      this.onChanged.emit(this.value)
    } catch (e) {
      throw "Load session error -> " + e;
    }
  }
}
export default Session