export default {
  namespaced: true,
  state: {
    session: null,
  },
  mutations:  {
    set (state,newSession) {
      let s = window.CSWebCore.session
      s.set(newSession)
    },
    clear (state) {
      let s = window.CSWebCore.session
      s.clear()
    },
    setRaw (state,newSession) {
      state.session = newSession;
    },
  },
  actions:    {
    bind (context) {
      let s = window.CSWebCore.session
      s.onChanged.on((newSess)=>{
        console.log('newSess',newSess)
        context.commit('setRaw', newSess)
      })
      context.commit('setRaw', s.value)
    },
  },
  getters: {    
  }
}